<template>
  <div class="card border-primary mb-2">
    <h5 class="card-header border-primary bg-primary text-white">ข่าวสาร</h5>
    <div class="card-body">
      <div class="form-group">
        <label for="news">ข้อความ</label>
        <textarea
        :readonly="!canEdit"
          class="form-control"
          id="news"
          rows="3"
          v-model="news"
        ></textarea>
      </div>
      <button class="btn btn-primary mt-2" @click="save" v-show="canEdit">ส่ง</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      news: "",
    }
  },
  computed:{
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes("setting_new_edit") 
    }
  },
  methods: {
    async save() {
      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "news",
          data: {
            news: this.news,
          },
        })

        this.swal.swalSuccess()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
}
</script>